.header-text{
    font-size: 6vw;
    vertical-align: middle;
    border-bottom: solid #444 .25vw;
  }

.header *{
    position: relative;
    float: left;
}
  
.header{
    width: 100%;
}
  