#blog image{
    filter: blur(5px);
    -webkit-filter: blur(5px);
    
}

#blog svg{
    opacity: 90%;
}

#blog a{
    color:#282c34;
}

.home .hex{
    fill: grey;
  }
  
  .home .cluster{
    height: 30vw;
    width: 100%;
  }
  
  .home .cluster .hex{
    position: relative;
    float: left;
    height: 100%;
    text-align: center;
  }
  
  .cluster .hex .link{
    display: flex;
    
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .cluster .hex .link a{
    color:white;
    font-size: 4vw;
    text-decoration: none;
    
  }
  
  .profile svg text{
    fill: rgba(255, 255, 255, 0.90);
    color: rgba(255, 255, 255, 0.90);
  }