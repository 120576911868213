body {
    font-size: 2vw;
    color: white;
}

h1{
    text-align: center;
}

.text p{
    position: relative;
    text-indent: 3vw;
}

.blog .header image{
    filter: blur(5px);
  -webkit-filter: blur(5px);
}